exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-feedback-invite-js": () => import("./../../../src/pages/feedback-invite.js" /* webpackChunkName: "component---src-pages-feedback-invite-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-unsubscribe-error-js": () => import("./../../../src/pages/unsubscribe-error.js" /* webpackChunkName: "component---src-pages-unsubscribe-error-js" */),
  "component---src-pages-unsubscribe-success-js": () => import("./../../../src/pages/unsubscribe-success.js" /* webpackChunkName: "component---src-pages-unsubscribe-success-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-pages-verify-email-error-js": () => import("./../../../src/pages/verify-email-error.js" /* webpackChunkName: "component---src-pages-verify-email-error-js" */),
  "component---src-pages-verify-email-success-js": () => import("./../../../src/pages/verify-email-success.js" /* webpackChunkName: "component---src-pages-verify-email-success-js" */)
}

